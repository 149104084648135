import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Modelo } from '../../models/modelo.model';
import { TaxFormInputComponent } from '../tax-form-input/tax-form-input.component';

import { Account } from '../../models/account.model';
@Component({
  selector: `app-spanish-tax-form`,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatDividerModule,
    MatRadioModule,
    TaxFormInputComponent,
    MatTooltipModule,
    MatSelectModule,
  ],
  providers: [CurrencyPipe],
  templateUrl: `./spanish-tax-form.component.html`,
  styleUrl: `./spanish-tax-form.component.scss`,
})
export class SpanishTaxFormComponent {
  @Input() accounts: Map<string, Account>;
  @Input() platform: string;
  @Input() token: string;
  @Input() coins: Map<string, string>;
  @Input() modelo: Modelo;

  @Input() firstSection = false;
  @Input() demo = false;
}
