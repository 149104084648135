/* eslint-disable indent */
export type Tooltip = `INCOME_TAX_TOOLTIP` | `MISC_INCOME` | `MOVABLE_INCOME` | `GENERAL_TAXABLE_BASE` | `SAVING_TAXABLE_BASE`;

export const TOOLTIPS: Map<Tooltip, any> = new Map<Tooltip, any>([
  [`INCOME_TAX_TOOLTIP`, {
    fr: `Taux d'imposition en fonction de vos revenus :

    Jusqu'à 11 294 € : 0 %
    De 11 295 € à 28 797 € : 11 %
    De 28 798 € à 82 341 € : 30 %
    De 82 342 € à 177 106 € : 41 %
    Plus de 177 107 € : 45 %`,

    en: `Tax rate based on your income:

    Up to €11,294: 0%
    From €11,295 to €28,797: 11%
    From €28,798 to €82,341: 30%
    From €82,342 to €177,106: 41%
    More than €177,107: 45%`,
  }],
  [`MISC_INCOME`, {
    fr: `Les revenus divers (ou revenus de trading), sont imposés à hauteur de 33% des bénéfices.
    
    Reportez-vous à notre questionnaire pour connaitre votre profil fiscal.`,
    en: `Miscellaneous incomes (or trading incomes) are taxed at 33% of profits.
    
    Refer to our questionnaire to know your tax profile.`
  }],
  [`MOVABLE_INCOME`, {
    fr: `Les revenus mobiliers (ou revenus passifs), sont imposés à hauteur de 30% des bénéfices.
    
    Reportez-vous à notre questionnaire pour connaitre votre profil fiscal.`,
    en: `
    Income from movable property (or passive income), are taxed at 30% of profits.
    
    Refer to our questionnaire to know your tax profile.`
  }],
  [`GENERAL_TAXABLE_BASE`, {
    en: `Tax rate based on your income:
    
    Up to €12,450: 19%
    From €12,450 to €20,199: 24%
    From €20,200 to €35,199: 30%
    From €35,200 to €59,999: 37%
    From €60,000 to €299,999: 45%
    More than €299,999: 47%

    To calculate the exact 'tipo autonómico', refer to the rate in your region.
    `,
    es: `Tipo impositivo en función de los ingresos:
    
    Hasta 12 450 € : 19 %
    De 12 450 € a 20 199 € : 24 %
    De 20 200 € a 35 199 € : 30 %
    De 35 200 € a 59 999 € : 37 %
    De 60 000 € a 299 999 € : 45 %
    Más de 299 999 € : 47 %

    Para calcular el 'tipo autonómico' exacto, consulte la tasa de su región.
    `
  }],
  [`SAVING_TAXABLE_BASE`, {
    en: `Tax rate based on your income:
    
    Up to €6,000: 19%
    From €6,000 to €50,000: 21%
    From €50,000 to €200,000: 23%
    From €200,000 to €300,000: 27%
    More than €300,000: 28%
    `,
    es: `Tipo impositivo en función de los ingresos:
    
    Hasta 6 000 € : 19 %
    De 6 000 € a 50 000 € : 21 %
    De 50 000 € a 200 000 € : 23 %
    De 200 000 € a 300 000 € : 27 %
    Más de 300 000 € : 28 %
    `
  }]
]);