import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { Account } from '../../models/account.model';
import { TaxFormInputComponent } from '../tax-form-input/tax-form-input.component';

@Component({
  selector: `app-french-tax-form`,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDividerModule, TaxFormInputComponent],
  templateUrl: `./french-tax-form.component.html`,
  styleUrl: `./french-tax-form.component.scss`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrenchTaxFormComponent {
  @Input() accounts: Map<string, Account>;
  @Input() platform: string;
}
