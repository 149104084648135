import { Injectable } from '@angular/core';
import { AppKit, createAppKit, Metadata } from '@reown/appkit';
import { Ethers5Adapter } from '@reown/appkit-adapter-ethers5';
import { SolanaAdapter } from '@reown/appkit-adapter-solana';
import {
  acala,
  arbitrum,
  astar,
  avalanche,
  base,
  bitcoin,
  bsc,
  celo,
  classic,
  cronos,
  cronoszkEVM,
  dogechain,
  fantom,
  harmonyOne,
  hedera,
  kcc,
  linea,
  mainnet,
  moonbeam,
  moonriver,
  optimism,
  polygon,
  ronin,
  scroll,
  sepolia,
  solana,
  zksync,
} from '@reown/appkit/networks';
import { environment } from '../../../environments/environment';
import { ethereumPow, okexChain } from '../../taxation/constants/account.constant';

@Injectable({
  providedIn: `root`,
})
export class Web3Service {
  private readonly ethers5Adapter: Ethers5Adapter = new Ethers5Adapter();
  private readonly solanaAdapter: SolanaAdapter = new SolanaAdapter();

  private readonly projectId = `41d3e9e02f9c8425bdee7b7a7a1cef67`;
  private readonly metadata: Metadata = {
    name: `Waltio`,
    description: `Waltio | The n°1 crypto tax tool 🇫🇷🇪🇸🇧🇪`,
    url: environment.url,
    icons: [`https://public.waltio.com/logo/w.square.blue.shaded.png`],
  };

  createModal(): AppKit {
    return createAppKit({
      themeMode: `light`,
      adapters: [this.ethers5Adapter, this.solanaAdapter],
      projectId: this.projectId,
      networks: [
        mainnet,
        bitcoin,
        solana,
        zksync,
        arbitrum,
        acala,
        astar,
        avalanche,
        bsc,
        base,
        celo,
        cronos,
        cronoszkEVM,
        dogechain,
        classic,
        fantom,
        harmonyOne,
        hedera,
        kcc,
        sepolia,
        linea,
        polygon,
        moonbeam,
        moonriver,
        optimism,
        ronin,
        scroll,
        ethereumPow,
        okexChain,
      ],
      metadata: this.metadata,
      features: {
        analytics: true, // Optional - defaults to your Cloud configuration
        allWallets: true,
        legalCheckbox: true,
        smartSessions: true,
        email: false,
        onramp: false,
        emailShowWallets: false,
        history: false,
        socials: false,
        swaps: false,
      },
      enableWalletConnect: false,
      debug: !environment.production,
    });
  }
}
