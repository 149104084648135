import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { TimezoneDetails } from '../../../shared/models/file.model';
import { NameResolverResponse } from '../../models/name-resolver-response.model';
import { UserAccount } from '../../models/user-account.model';
import * as accountReducer from '../reducers/account.reducer';
import { Upload } from '../../models/upload.model';
import { Candidate } from '../../models/candidate.model';
import { AppKit } from '@reown/appkit';
import { ReownModalState } from '../../models/reown.model';

export const ACCOUNTS_FEATURE_NAME = `accounts`;

export interface State {
  accounts: accountReducer.State;
}

export const selectUploadState: MemoizedSelector<State, accountReducer.State> =
  createFeatureSelector<accountReducer.State>(ACCOUNTS_FEATURE_NAME);

export const selectUserAccounts: MemoizedSelector<State, UserAccount[]> = createSelector(
  selectUploadState,
  (state: accountReducer.State) => state.userAccounts,
);

export const selectTransactionsCount: MemoizedSelector<State, Map<string, number>> = createSelector(
  selectUploadState,
  (state: accountReducer.State) => state.transactionsCount,
);

export const selectIsApiConnected: MemoizedSelector<State, boolean> = createSelector(
  selectUploadState,
  (state: accountReducer.State) => state.api.isConnected,
);

export const selectApiErrorMessage: MemoizedSelector<State, string> = createSelector(
  selectUploadState,
  (state: accountReducer.State) => state.api.errorMessage,
);

export const selectAvailableCandidates: MemoizedSelector<State, Candidate[]> = createSelector(
  selectUploadState,
  (state: accountReducer.State) => state.availableCandidates,
);

export const selectResolvedName: MemoizedSelector<State, NameResolverResponse> = createSelector(
  selectUploadState,
  (state: accountReducer.State) => state.resolvedName,
);

export const selectTimezones: MemoizedSelector<State, TimezoneDetails[]> = createSelector(
  selectUploadState,
  (state: accountReducer.State) => state.timezones,
);

export const selectUserAPIs: MemoizedSelector<State, Upload[]> = createSelector(
  selectUploadState,
  (state: accountReducer.State) => state.userAPIs,
);

export const selectReownModal: MemoizedSelector<State, AppKit> = createSelector(
  selectUploadState,
  (state: accountReducer.State) => state.reownModal,
);

export const selectReownModalState: MemoizedSelector<State, ReownModalState> = createSelector(
  selectUploadState,
  (state: accountReducer.State) => state.reownModalState,
);
