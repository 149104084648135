import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, Input, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { TruncatePipe } from './../../pipes/truncate.pipe';

@Component({
  selector: `app-tax-form-input`,
  standalone: true,
  imports: [CommonModule, MatTooltipModule, TranslateModule],
  providers: [TruncatePipe],
  templateUrl: `./tax-form-input.component.html`,
  styleUrls: [`./tax-form-input.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxFormInputComponent {
  @ViewChild(`tooltip`) tooltip: MatTooltip;

  @Input() set value(value: number | string) {
    if (typeof value === `number`) {
      this._value = this.currencyPipe.transform(value, ``, ``, `0.0-6`);
      this.valueToDisplay = this.truncatePipe.transform(this._value, 20, true, `...`);
    } else {
      this._value = value?.toString();
      this.valueToDisplay = value;
    }
  }
  get value(): string {
    return this._value;
  }

  @Input() copiable = false;
  @Input() selectable = false;
  @Input() primary = false;
  @Input() disabled = false;

  private _value: string;

  tooltipLabel = `COPY`;
  copied = false;
  valueToDisplay: string;

  constructor(
    public readonly clipboardService: ClipboardService,
    private readonly truncatePipe: TruncatePipe,
    private readonly currencyPipe: CurrencyPipe,
  ) {}

  copyValue(): void {
    if (this.copiable) {
      this.copied = true;
      this.clipboardService.copyFromContent(this._value);
      this.tooltipLabel = `COPIED`;
      this.tooltip.show();
    }
  }

  resetCopied(): void {
    this.copied = false;
    this.tooltipLabel = `COPY`;
    this.tooltip?.hide();
  }
}
