import {
  AppKitNetwork,
  arbitrum,
  astar,
  avalanche,
  base,
  classic,
  cronos,
  fantom,
  harmonyOne,
  kcc,
  linea,
  mainnet,
  moonbeam,
  moonriver,
  optimism,
  polygon,
  ronin,
  rootstock,
  scroll,
  solana,
  zksync,
} from '@reown/appkit/networks';

import { defineChain } from '@reown/appkit/networks';

export const ethereumPow = defineChain({
  id: 10001, // Remplacez par l`ID de chaîne approprié pour Ethereum PoW
  caipNetworkId: `eip155:10001`,
  chainNamespace: `eip155`,
  name: `Ethereum PoW`,
  nativeCurrency: {
    decimals: 18,
    name: `EthereumPoW`,
    symbol: `ETHW`,
  },
  rpcUrls: {
    default: {
      http: [`https://mainnet.ethereumpow.org`], // Remplacez par l`URL RPC appropriée
    },
  },
  blockExplorers: {
    default: {
      name: `ETHWScan`,
      url: `https://mainnet.ethwscan.com`, // Remplacez par l`URL de l`explorateur de blocs approprié
    },
  },
});

export const okexChain = defineChain({
  id: 66,
  caipNetworkId: `eip155:66`,
  chainNamespace: `eip155`,
  name: `OKExChain Mainnet`,
  nativeCurrency: {
    decimals: 18,
    name: `OKT`,
    symbol: `OKT`,
  },
  rpcUrls: {
    default: {
      http: [`https://exchainrpc.okex.org`],
    },
  },
  blockExplorers: {
    default: {
      name: `OKLink`,
      url: `https://www.oklink.com/okexchain/`,
    },
  },
});

export const REOWN_WALLETS: Map<string, string> = new Map([
  [`MetaMask`, `METAMASK`],
  [`Phantom`, `PHANTOM`],
  [`Exodus`, `WALLET_EXODUS`],
  [`Zelcore`, `ZELCORE`],
  [`Blade Wallet`, `BLADE_WALLET`],
  [`Zerion`, `WALLET_ZERION`],
  [`Keplr`, `KEPLR`],
  [`Tangem Wallet`, `TANGEM`],
  // [`xPortal`, `MAIAR_APP`],
  [`Argent`, `ARGENT`],
  [`Coinbase Wallet`, `COINBASE_WALLET`],
  [`MOONSTAKE`, `MOONSTAKE`],
  [`Talisman Wallet`, `TALISMAN`],
  [`Bridge Wallet`, `BRIDGE_WALLET`],
  [`Zengo Wallet`, `ZENGO`],
  [`Bitget Wallet`, `BITGET_WALLET`],
  [`Rabby Wallet`, `RABBY`],
  [`SafePal`, `SAFEPAL`],
  [`ioPay`, `IOPAY`],
  [`THORWallet`, `VECHAINTHORWALLET`],
  [`Rainbow`, `RAINBOW`],
  [`MathWallet`, `MATH_WALLET`],
  [`XDEFI Wallet`, `XDEFI_WALLET`],
  // [`Leap Cosmos Wallet`, `LEAP`],
  // [`Cosmostation`, `COSMOSTATION`],
  [`OKX Wallet`, `OKX_WALLET`],
  [`Core`, `CORE_APP`],
  [`Nova Wallet`, `NOVA_WALLET`],
  [`Safe`, `SAFE`],
  [`Crypto.com | DeFi Wallet`, `CRYPTO_COM_DEFI_WALLET`],
  [`Solflare`, `SOLFLARE`],
  [`Trust Wallet`, `TRUST_WALLET`],
  [`Binance Web3 Wallet`, `BINANCE_WALLET`],
  [`Backpack`, `BACKPACK`],
  [`Uniswap Wallet`, `UNISWAP_CRYPTO_WALLET`],
  [`Valora`, `VALORA`],
  [`D'CENT Wallet`, `DCENT_WALLET`],
  [`Ledger Live`, `LEDGER_LIVE`],
  [`Blockchain.com`, `WALLET_BLOCKCHAIN_COM`],
]);

export const REOWN_BLOCKCHAINS: Map<string, string> = new Map([
  [`1`, `WALLET_ETH`],
  [`43114`, `WALLET_AVALANCHE`],
  [`5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp`, `SOLANA`],
  [`324`, `WALLET_ZKSYNC`],
  [`42161`, `WALLET_ARBITRUM`],
  [`3776`, `WALLET_ASTAR`],
  [`8453`, `WALLET_BASE`],
  [`56`, `WALLET_BSC`],
  [`42220`, `WALLET_CELO`],
  [`25`, `WALLET_CRONOS`],
  [`61`, `WALLET_ETC`],
  [`10001`, `WALLET_ETHW`],
  [`250`, `WALLET_FANTOM`],
  [`1666600000`, `WALLET_HARMONY`],
  [`1666600001`, `WALLET_HARMONY`],
  [`1666600002`, `WALLET_HARMONY`],
  [`1666600003`, `WALLET_HARMONY`],
  [`321`, `WALLET_KCC`],
  [`59144`, `WALLET_LINEA`],
  [`137`, `WALLET_MATIC`],
  [`1101`, `WALLET_MATIC`],
  [`1284`, `WALLET_MOONBEAM`],
  [`1285`, `WALLET_MOONRIVER`],
  [`66`, `WALLET_OKEXCHAIN`],
  [`10`, `WALLET_OPTIMISM`],
  [`2020`, `WALLET_RONIN`],
  [`30`, `WALLET_RSK`],
  [`534352`, `WALLET_SCROLL`],
  // [`tezos:mainnet`, `TEZOS`],
  // [`787`, `WALLET_ACALA`],
  // [`algorand:wGHE2Pwdvd7S12BL5FaOP20EGYesN73k`, `WALLET_ALGORAND`],
  // [`cip-34:1-764824073`, `WALLET_CARDANO`],
  // [`1024`, `WALLET_CLOVER`],
  // [`2000`, `WALLET_DOGE`],
  // [`mvx:1`, `WALLET_ERD`],
  // [`hedera:mainnet`, `WALLET_HEDERA`],
  // [`686`, `WALLET_KARURA`],
  // [`polkadot:b0a8d493285c2df73290dfb7e61f870f`, `WALLET_KUSAMA`],
  // [`mina:mainnet`, `WALLET_MINA`],
  // [`near:mainnet`, `WALLET_NEAR`],
  // [`polkadot:91b171bb158e2d3848fa23a9f1c25182`, `WALLET_POLKADOT`],
  // [`1022`, `WALLET_SAKURA`],
  // [`cosmos:columbus-4`, `WALLET_TERRA`],
  // [`stellar:pubnet`, `WALLET_STELLAR`],
  // [`cosmos:cosmoshub-4`, `WALLET_COSMOS`],
  // [`bip122:000000000019d6689c085ae165831e93`, `WALLET_BTC`],
]);

// @ts-ignore
export const REOWN_NETWORKS: Map<string, AppKitNetwork> = new Map([
  [`WALLET_ETH`, mainnet],
  [`WALLET_AVALANCHE`, avalanche],
  [`SOLANA`, solana],
  [`WALLET_ZKSYNC`, zksync],
  [`WALLET_ARBITRUM`, arbitrum],
  [`WALLET_ASTAR`, astar],
  [`WALLET_BASE`, base],
  [`WALLET_CRONOS`, cronos],
  [`WALLET_ETC`, classic],
  [`WALLET_ETHW`, ethereumPow],
  [`WALLET_FANTOM`, fantom],
  [`WALLET_HARMONY`, harmonyOne],
  [`WALLET_KCC`, kcc],
  [`WALLET_LINEA`, linea],
  [`WALLET_MATIC`, polygon],
  [`WALLET_MOONBEAM`, moonbeam],
  [`WALLET_MOONRIVER`, moonriver],
  [`WALLET_OKEXCHAIN`, okexChain],
  [`WALLET_OPTIMISM`, optimism],
  [`WALLET_RONIN`, ronin],
  [`WALLET_RSK`, rootstock],
  [`WALLET_SCROLL`, scroll],
]);
