<div
  class="form-input"
  [ngClass]="{
    'form-input--copied': copied,
    'form-input--copiable': copiable,
    'form-input--selectable': selectable,
    'form-input--primary': primary,
  }"
  [matTooltip]="'ENTER_ON_TAX_WEBSITE' | translate"
  matTooltipPosition="above"
  [matTooltipDisabled]="copiable || selectable || primary || disabled"
  (click)="copyValue()"
  (mouseleave)="resetCopied()"
>
  @if (valueToDisplay) {
    {{ valueToDisplay }}
  } @else {
    <div></div>
  }

  @if (copiable) {
    <div #tooltip="matTooltip" class="copy" [matTooltip]="tooltipLabel | translate" matTooltipPosition="left"></div>
  }

  @if (selectable) {
    <div class="select"></div>
  }
</div>
