export const TAX_FORM_PLATFORMS: string[] = [
  `ASCENDEX`,
  `BINANCE`,
  `BITFINEX`,
  `BITMEX`,
  `BITMART`,
  `BITPANDA`,
  `BITSTAMP`,
  `BITTREX`,
  `BITVAVO`,
  `BLOCKFI`,
  `BYBIT`,
  `CELSIUS`,
  `COINBASE_PRO`,
  `COINBASE`,
  `COINBASE_PRIME`,
  `COINBENE`,
  `COINLIST`,
  `COINLIST_PRO`,
  `COSS`,
  `CREX24`,
  `CRYPTO_COM`,
  `ETORO`,
  `FTX`,
  `GATE_IO`,
  `HITBTC`,
  `HOTBIT`,
  `HUOBI`,
  `KRAKEN`,
  `KUCOIN`,
  `LIQUID`,
  `NEXO`,
  `PAYEER`,
  `POLONIEX`,
  `REVOLUT`,
  `SWISSBORG`,
  `UPHOLD`,
  `VIRWOX`,
  `WIREX`,
  `YIELD`,
  `YOUHODLER`,
  `COINMERCE`,
  `COINMETRO`,
  `PROBIT`,
  `COINZOOM`,
  `CRYPTO_COM_APP`,
  `OKEX`,
  `CAKEDEFI`,
  `CEX_IO`,
  `WALLET_BLOCKCHAIN_COM`,
  `CRYPTOPAY`,
  `FAIRMINT`,
  `LIVECOIN`,
  `NICEHASH`,
  `MEXC`,
  `BITGET`,
];

export const SPAIN_TAX_FORM_PLATFORMS: string[] = [
  `ASCENDEX`,
  `PAYMIUM`,
  `BINANCE`,
  `BITFINEX`,
  `BITMEX`,
  `BITMART`,
  `BITPANDA`,
  `BITSTAMP`,
  `BITTREX`,
  `BITVAVO`,
  `BLOCKFI`,
  `BYBIT`,
  `CELSIUS`,
  `COINBASE_PRO`,
  `COINBASE`,
  `COINBASE_PRIME`,
  `COINBENE`,
  `COINLIST`,
  `COINLIST_PRO`,
  `COSS`,
  `CREX24`,
  `CRYPTO_COM`,
  `ETORO`,
  `FTX`,
  `GATE_IO`,
  `HITBTC`,
  `HOTBIT`,
  `HUOBI`,
  `KRAKEN`,
  `KUCOIN`,
  `LIQUID`,
  `NEXO`,
  `PAYEER`,
  `POLONIEX`,
  `REVOLUT`,
  `SWISSBORG`,
  `UPHOLD`,
  `VIRWOX`,
  `WIREX`,
  `YIELD`,
  `YOUHODLER`,
  `COINMERCE`,
  `COINMETRO`,
  `PROBIT`,
  `COINZOOM`,
  `CRYPTO_COM_APP`,
  `OKEX`,
  `CAKEDEFI`,
  `CEX_IO`,
  `WALLET_BLOCKCHAIN_COM`,
  `CRYPTOPAY`,
  `FAIRMINT`,
  `LIVECOIN`,
  `NICEHASH`,
  `MEXC`,
  `BITGET`,
];

export const FORM_2086_CESSIONS_DEMO: any[] = [
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
  {
    211: `01/03/2021`,
    212: `119760`,
    213: `8000`,
    214: `0`,
    215: `8000`,
    216: `0`,
    217: `8000`,
    218: `8000`,
    219: `8000`,
    220: `0`,
    221: `8000`,
    222: `0`,
    223: `8000`,
  },
];
