import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as fromPayment from '../../../store/selectors/payment.selector';
import * as fromShared from '../../../../shared/store/selectors/shared.selector';
import * as fromAffiliation from '../../../store/selectors/affiliation.selector';
import { Store, select } from '@ngrx/store';
import { UtilsService } from '../../../../shared/services/utils.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CheckoutType, PaymentEstimateV3, PaymentMethod, PendingCharge } from '../../../models/payment.model';
import { CurrencyDecimalPipe } from '../../../../shared/pipes/currency-decimal.pipe';
import { UserPreferences } from '../../../../shared/models/user-preferences.model';
import { PaymentMethodComponent } from '../../payment-method/payment-method.component';
import { VaultComponent } from '../../vault/vault.component';
import { Vault } from '../../../models/vault.model';
import { loadVaultAction } from '../../../store/actions/affiliation.action';
import { PLANS } from '../../../constants/plans.constant';
import { MatButtonModule } from '@angular/material/button';
import { VoucherComponent } from '../../voucher/voucher.component';
import { GenericResponse } from '../../../../shared/models/generic-response.model';
import {
  checkVoucherCodeAction,
  enableAddonAction,
  loadPaymentEstimateAction,
  payChargeAction,
  setVoucherResponseAction,
  upgradePlanAction,
} from '../../../store/actions/payment.action';
import moment, { Moment } from 'moment';
import { SUBSCRIPTION_RENEW_DAY, SUBSCRIPTION_RENEW_MONTH } from '../../../constants/payment.constant';

@Component({
  selector: `app-checkout-dialog`,
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDividerModule,
    TranslateModule,
    CurrencyDecimalPipe,
    PaymentMethodComponent,
    VaultComponent,
    VoucherComponent,
  ],
  templateUrl: `./checkout-dialog.component.html`,
  styleUrls: [`./checkout-dialog.component.scss`],
})
export class CheckoutDialogComponent implements OnInit {
  currentYear = moment().year();
  checkoutType: CheckoutType;
  paymentEstimate: PaymentEstimateV3;
  pendingCharge: PendingCharge;
  plans = PLANS;
  stablecoins: string[];
  fiats: string[];
  userPreferences: UserPreferences;

  selectedPaymentMethod: PaymentMethod = `STRIPE`;
  showOtherPaymentMethods = false;

  vaultBalance: number;
  useVault = false;
  useVoucher = false;
  voucherCode = ``;
  voucherResponse: GenericResponse;
  papCookie: string;
  subscribeCTA = ``;
  planLabel = ``;
  selectedPlanLabel = ``;
  vaultBalanceUsed = 0;

  subscriptionRenewalMessage = ``;

  private readonly destroy$ = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<CheckoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { checkoutType: CheckoutType },
    private readonly paymentStore$: Store<fromPayment.State>,
    private readonly sharedStore$: Store<fromShared.State>,
    private readonly affiliationStore$: Store<fromAffiliation.State>,
    private readonly utilsService: UtilsService,
    private readonly translateService: TranslateService,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    this.checkoutType = this.data.checkoutType;
    this.papCookie = this.utilsService.getCookie(`PAPVisitorId`);

    localStorage.setItem(`payment_callback_url`, this.location.path());

    this.affiliationStore$.dispatch(loadVaultAction());
    this.paymentStore$.dispatch(setVoucherResponseAction({ voucherResponse: null }));

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectStableCoins),
        map((stablecoins: string[]) => {
          this.stablecoins = stablecoins;
        })
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectFiats),
        map((fiats: string[]) => {
          this.fiats = fiats;
        })
      )
      .subscribe();

    this.sharedStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromShared.selectUserPreferences),
        map((userPreferences: UserPreferences) => {
          this.userPreferences = userPreferences;
        })
      )
      .subscribe();

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectPaymentEstimate),
        map((paymentEstimate: PaymentEstimateV3) => {
          this.paymentEstimate = paymentEstimate;

          this.vaultBalanceUsed =
            this.paymentEstimate?.appliedVouchers?.find((voucher) => voucher.code === `Vault`)?.amount ?? 0;

          if (this.paymentEstimate?.selectedPlan) {
            this.selectedPlanLabel = this.translateService.instant(`SELECTED_YEAR_FOR`, {
              year: this.paymentEstimate.fiscalYear,
            });
            this.planLabel = this.translateService.instant(`PLAN_SHORT`, {
              plan: this.translateService.instant(`PLANS.${this.paymentEstimate.selectedPlan}`),
            });
            this.subscribeCTA = this.translateService.instant(`SUBSCRIBE_TO_PLAN`, {
              plan: this.translateService.instant(`PLANS.${this.paymentEstimate.selectedPlan}`),
            });
            this.computeSubscriptionRenewalDate();
          }
        })
      )
      .subscribe();

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectPendingCharge),
        map((pendingCharge: PendingCharge) => {
          this.pendingCharge = pendingCharge;
        })
      )
      .subscribe();

    this.affiliationStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromAffiliation.selectVault),
        map((vault: Vault) => {
          this.vaultBalance = vault?.balance;
        })
      )
      .subscribe();

    this.paymentStore$
      .pipe(
        takeUntil(this.destroy$),
        select(fromPayment.selectVoucherResponse),
        map((voucherResponse: GenericResponse) => {
          this.voucherResponse = voucherResponse;
          this.useVoucher = this.voucherResponse?.success;

          if (!this.useVoucher) {
            this.voucherCode = ``;
          }
        })
      )
      .subscribe();
  }

  selectPaymentMethod(paymentMethod: PaymentMethod): void {
    this.selectedPaymentMethod = paymentMethod;
  }

  payCharge(): void {
    this.paymentStore$.dispatch(payChargeAction({ paymentMethod: this.selectedPaymentMethod }));
  }

  upgradePlan(): void {
    this.paymentStore$.dispatch(
      upgradePlanAction({
        paymentMethod: this.selectedPaymentMethod,
        requestedPlan: this.paymentEstimate.selectedPlan,
        fiscalYear: this.paymentEstimate.fiscalYear,
        papCookie: this.papCookie,
        useVault: this.useVault,
        code: this.voucherCode,
      })
    );
  }

  enableAddon(): void {
    this.paymentStore$.dispatch(
      enableAddonAction({
        paymentMethod: this.selectedPaymentMethod,
        requestedAddOn: this.paymentEstimate.addon,
        fiscalYear: this.paymentEstimate.fiscalYear,
        useVault: this.useVault,
        code: this.voucherCode,
      })
    );
  }

  useVaultChange(useVault: boolean): void {
    this.useVault = useVault;
    this.getPaymentEstimate();
  }

  checkVoucherCode(voucherCode: string): void {
    this.voucherCode = voucherCode;

    if (this.checkoutType === `PLAN`) {
      this.paymentStore$.dispatch(
        checkVoucherCodeAction({
          requestedPlan: this.paymentEstimate.selectedPlan,
          voucherCode,
          fiscalYear: this.paymentEstimate.fiscalYear,
          checkoutType: this.checkoutType,
          useVault: this.useVault,
        })
      );
    } else if (this.checkoutType === `ADDON`) {
      this.paymentStore$.dispatch(
        checkVoucherCodeAction({
          requestedAddOn: this.paymentEstimate.addon,
          voucherCode,
          fiscalYear: this.paymentEstimate.fiscalYear,
          checkoutType: this.checkoutType,
          useVault: this.useVault,
        })
      );
    }
  }

  removeVoucher(): void {
    this.paymentStore$.dispatch(setVoucherResponseAction({ voucherResponse: null }));
    this.getPaymentEstimate();
  }

  getPaymentEstimate(): void {
    this.paymentStore$.dispatch(
      loadPaymentEstimateAction({
        fiscalYear: this.paymentEstimate.fiscalYear,
        checkoutType: this.checkoutType,
        requestedPlan: this.paymentEstimate.selectedPlan,
        requestedAddOn: this.paymentEstimate.addon,
        useVault: this.useVault,
        code: this.voucherCode,
      })
    );
  }

  computeSubscriptionRenewalDate(): void {
    let nextRenewDate: Moment;

    if (this.currentYear > this.paymentEstimate.fiscalYear) {
      nextRenewDate = moment({
        year: this.currentYear,
        month: SUBSCRIPTION_RENEW_MONTH,
        date: SUBSCRIPTION_RENEW_DAY,
      });
    } else {
      nextRenewDate = moment({
        year: this.currentYear + 1,
        month: SUBSCRIPTION_RENEW_MONTH,
        date: SUBSCRIPTION_RENEW_DAY,
      });
    }

    this.subscriptionRenewalMessage = this.translateService.instant(`SUBSCRIPTION_RENEW`, {
      date: nextRenewDate.format(`LL`),
    });
  }
}
