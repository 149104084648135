/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { User as AuthUser } from '@auth0/auth0-angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import md5 from 'md5';
import PouchDB from 'pouchdb';
import { EMPTY } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import * as fromAuth from '../../../authentication/store/selectors/authentication.selector';
import { CustomError } from '../../../shared/models/error.model';
import { GenericResponse } from '../../../shared/models/generic-response.model';
import { ToastService } from '../../../shared/services/toast.service';
import { Assessment, AssessmentStatus } from '../../models/assessment.model';
import { FiscalYearPlanEstimate } from '../../models/payment.model';
import { UserAccount } from '../../models/user-account.model';
import { AssessmentService } from '../../services/assessment.service';
import {
  computeAssessmentStatusAction,
  downloadAssessmentFileAction,
  downloadAssessmentFileAsPDFAction,
  getLatestAssessmentsByYearAction,
  loadAssessmentAction,
  setAssessmentAction,
  setAssessmentStatusAction,
  setLatestAssessmentsByYearAction,
  setModeloAction,
  setTaxableCessionsAction,
  startAssessmentAction,
  toggleAutomaticAssessmentAction,
  unsyncAssessmentAction,
} from '../actions/assessment.action';
import * as fromAccount from '../selectors/account.selector';
import * as fromAssessment from '../selectors/assessment.selector';
import * as fromPayment from '../selectors/payment.selector';
import { loadUserAction } from '../../../authentication/store/actions/authentication.action';
import { Modelo, ModeloPlatform } from '../../models/modelo.model';

@Injectable()
export class AssessmentEffects {
  getLatestAssessmentsByYear$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof getLatestAssessmentsByYearAction>>(getLatestAssessmentsByYearAction),
      switchMap((action: ReturnType<typeof getLatestAssessmentsByYearAction>) =>
        this.assessmentService.getLatestAssessmentsByYear().pipe(
          switchMap((assessments: Map<string, Assessment>) => {
            assessments.forEach((assessment: Assessment, year: string) => {
              assessments.set(year, this.convertAssessment(assessment));
            });

            return [setLatestAssessmentsByYearAction({ assessmentsByYear: assessments })];
          })
        )
      )
    )
  );

  downloadAssessmentFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof downloadAssessmentFileAction>>(downloadAssessmentFileAction),
      mergeMap((action: ReturnType<typeof downloadAssessmentFileAction>) =>
        this.assessmentService.downloadAssessmentFile(action.assessmentId, action.filename).pipe(
          mergeMap((data: any) => {
            if (action.filename.includes(`.json`)) {
              if (action.fileType === `MODELO_721`) {
                const modelo: Modelo = data;

                modelo.platforms = new Map(Object.entries(modelo.platforms));
                modelo.platforms.forEach((platform: ModeloPlatform) => {
                  platform.quantities = new Map(Object.entries(platform.quantities));
                  platform.prices = new Map(Object.entries(platform.prices));
                  platform.acquisitionPrice = new Map(Object.entries(platform.acquisitionPrice));
                });
                
                return [setModeloAction({ modelo })];
              } else {
                return [setTaxableCessionsAction({ taxableCessions: data })];
              }
            } else {
              const link = document.createElement(`a`);
              link.href = window.URL.createObjectURL(new Blob([data], { type: data.type }));

              link.download = action.filename;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              return EMPTY;
            }
          })
        )
      )
    )
  );

  downloadAssessmentFileAsPDF$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof downloadAssessmentFileAsPDFAction>>(downloadAssessmentFileAsPDFAction),
        switchMap((action: ReturnType<typeof downloadAssessmentFileAsPDFAction>) =>
          this.assessmentService.downloadAssessmentFileAsPDF(action.assessmentId, action.filename).pipe(
            switchMap((data: Blob) => {
              const link = document.createElement(`a`);
              link.href = window.URL.createObjectURL(new Blob([data], { type: data.type }));

              link.download = action.filename.replace(`.xlsx`, `.pdf`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              return EMPTY;
            }),
            catchError((error: CustomError) => {
              this.toastService.error(error.message);

              return EMPTY;
            })
          )
        )
      ),
    { dispatch: false }
  );

  startAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof startAssessmentAction>>(startAssessmentAction),
      switchMap((action: ReturnType<typeof startAssessmentAction>) =>
        this.assessmentService
          .startAssessment(action.fiscalYear)
          .pipe(map((assessment: Assessment) => setAssessmentAction({ assessment })))
      )
    )
  );

  loadAssessment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof loadAssessmentAction>>(loadAssessmentAction),
        withLatestFrom(
          this.authStore.pipe(select(fromAuth.selectAccessToken)),
          this.authStore.pipe(select(fromAuth.selectAuthUser))
        ),
        switchMap(([action, accessToken, authUser]: [ReturnType<typeof loadAssessmentAction>, string, AuthUser]) => {
          const user = md5(sessionStorage.waltio_user || authUser.email);

          if (!this.pouchDB && !this.remoteCouchDB) {
            // Init DBs
            this.pouchDB = new PouchDB(`assessment-db`);
            this.remoteCouchDB = new PouchDB(`${environment.apiUrl}/${environment.couchDbUrl}`, {
              fetch: (url, opts): any => {
                // @ts-ignore
                opts.headers.set(`Authorization`, `Bearer ${accessToken}`);

                return PouchDB.fetch(url, opts);
              },
            });

            // Sync both DBs
            this.pouchDB.sync(this.remoteCouchDB, {
              live: true,
              retry: true,
              doc_ids: [user],
            });

            // Start listening to DB changes
            this.pouchDB
              .changes({
                since: `now`,
                live: true,
                doc_ids: [user],
                include_docs: true,
              })
              .on(`change`, (res: any) => {
                if (res.doc.nbOfWarningsbyType) {
                  res.doc.nbOfWarningsbyType = new Map<string, number>(Object.entries(res.doc.nbOfWarningsbyType));
                }

                this.assessmentStore$.dispatch(setAssessmentAction({ assessment: this.convertAssessment(res.doc) }));
              });
          }

          return EMPTY;
        })
      ),
    {
      dispatch: false,
    }
  );

  setAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof setAssessmentAction>>(setAssessmentAction),
      map((action: ReturnType<typeof setAssessmentAction>) => {
        return computeAssessmentStatusAction();
      })
    )
  );

  toggleAutomaticAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof toggleAutomaticAssessmentAction>>(toggleAutomaticAssessmentAction),
      switchMap((action: ReturnType<typeof toggleAutomaticAssessmentAction>) =>
        this.assessmentService.toggleAutomaticAssessment().pipe(
          switchMap((res: GenericResponse) => {
            if (res.success) {
              return [loadUserAction()];
            }
            return EMPTY;
          }),
          catchError((error: CustomError) => {
            this.toastService.error(error.message);

            return EMPTY;
          })
        )
      )
    )
  );

  computeAssessmentStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnType<typeof computeAssessmentStatusAction>>(computeAssessmentStatusAction),
      withLatestFrom(
        this.assessmentStore$.pipe(select(fromAssessment.selectAssessment)),
        this.accountStore$.pipe(select(fromAccount.selectUserAccounts)),
        this.paymentStore$.pipe(select(fromPayment.selectPlansByFiscalYears))
      ),
      switchMap(
        ([action, assessment, userAccounts, plansByFiscalYears]: [
          ReturnType<typeof computeAssessmentStatusAction>,
          Assessment,
          UserAccount[],
          Map<string, FiscalYearPlanEstimate>,
        ]) => {
          let assessmentStatus: AssessmentStatus = null;

          if (assessment && userAccounts && plansByFiscalYears) {
            const accountsSyncing =
              assessment.scheduleStatus === `DATA_NOT_READY` ||
              userAccounts?.some((account) => account.status === `UPLOADED`);

            const exceededPlan = assessment.scheduleStatus === `EXCEEDED_PLAN`;
            const needUpgrade = plansByFiscalYears.get(assessment.fiscalYear.toString())?.needUpgrade;
            const hasPaid = !exceededPlan && !needUpgrade;

            const hasManualTransactions =
              (userAccounts?.find((account: UserAccount) => account.type === `HIDDEN`)?.nbOfTransactions || 0) > 0;
            const hasAccounts = userAccounts?.filter((account: UserAccount) => account.type !== `HIDDEN`).length > 0;
            const hasTransactions = hasManualTransactions || hasAccounts; // assessment.nbOfAccounts > 0;

            if (!hasPaid) {
              assessmentStatus = `NEED_PLAN_UPGRADE`;
            } else if (!hasTransactions) {
              assessmentStatus = `NEED_ACCOUNT_ADD`;
            } else if (accountsSyncing) {
              assessmentStatus = `RETRIEVING_TX`;
            } else {
              switch (assessment.state) {
                case `COMPLETED`:
                case `IN_ERROR`:
                  assessmentStatus = `COMPLETED`;
                  break;
                default:
                  assessmentStatus = `IN_PROGRESS`;
              }
            }
          }

          return [setAssessmentStatusAction({ assessmentStatus })];
        }
      )
    )
  );

  unsyncAssessment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ReturnType<typeof unsyncAssessmentAction>>(unsyncAssessmentAction),
        switchMap(() => {
          if (this.pouchDB && this.remoteCouchDB) {
            this.pouchDB.destroy();
          }
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  // Pouch DB (local)
  private pouchDB: PouchDB.Database;

  // Couch DB (remote)
  private remoteCouchDB: PouchDB.Database;

  constructor(
    private readonly actions$: Actions,
    private readonly authStore: Store<fromAuth.State>,
    private readonly assessmentStore$: Store<fromAssessment.State>,
    private readonly accountStore$: Store<fromAccount.State>,
    private readonly paymentStore$: Store<fromPayment.State>,
    private readonly assessmentService: AssessmentService,
    private readonly toastService: ToastService
  ) {}

  convertAssessment(assessment: Assessment): Assessment {
    if (assessment?.summary) {
      assessment.summary.amountByLabelsForWithdrawals = new Map<string, number>(
        Object.entries(assessment.summary.amountByLabelsForWithdrawals)
      );

      assessment.summary.amountByLabelsForDeposits = new Map<string, number>(
        Object.entries(assessment.summary.amountByLabelsForDeposits)
      );

      assessment.summary.pnLByLabelsForWithdrawals = new Map<string, number>(
        Object.entries(assessment.summary.pnLByLabelsForWithdrawals)
      );

      assessment.summary.feesInFIATCollectedByCurrencyOnTaxableTransactions = new Map<string, number>(
        Object.entries(assessment.summary.feesInFIATCollectedByCurrencyOnTaxableTransactions)
      );

      // Parsing into Map of Map 🤯
      const amountByLabelAndCurrencyForDepositsMap = new Map<string, Map<string, number>>();
      Object.entries(assessment.summary.amountByLabelAndCurrencyForDeposits).forEach(
        ([label, currencies]: [string, Map<string, number>]) => {
          const currenciesMap = new Map<string, number>(Object.entries(currencies));
          amountByLabelAndCurrencyForDepositsMap.set(label, currenciesMap);
        }
      );
      assessment.summary.amountByLabelAndCurrencyForDeposits = amountByLabelAndCurrencyForDepositsMap;

      const amountByLabelAndCurrencyForWithdrawalsMap = new Map<string, Map<string, number>>();
      Object.entries(assessment.summary.amountByLabelAndCurrencyForWithdrawals).forEach(
        ([label, currencies]: [string, Map<string, number>]) => {
          const currenciesMap = new Map<string, number>(Object.entries(currencies));
          amountByLabelAndCurrencyForWithdrawalsMap.set(label, currenciesMap);
        }
      );
      assessment.summary.amountByLabelAndCurrencyForWithdrawals = amountByLabelAndCurrencyForWithdrawalsMap;
    }

    if (assessment?.nbOfWarningsbyType) {
      assessment.nbOfWarningsbyType = new Map<string, number>(Object.entries(assessment.nbOfWarningsbyType));
    }

    return assessment;
  }
}
